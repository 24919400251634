.fadeIn {
  animation: 0.1s fadeIn ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
}

.fadeInLeft {
  animation: 0.8s cubic-bezier(0, 1.25, 0.75, 1.05) fadeInLeft;
}

.fadeInRight {
  animation: 0.8s cubic-bezier(0, 1.25, 0.75, 1.05) fadeInRight;
}

.fadeInTop,
.modal.fade.show {
  animation: 0.8s cubic-bezier(0, 1.25, 0.75, 1.05) fadeInTop;
}

.fadeInBottom {
  animation: 0.8s cubic-bezier(0, 1.25, 0.75, 1.05) fadeInBottom;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(5rem);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(-5rem);
  }
}

@keyframes fadeInTop {
  from {
    opacity: 0;
    transform: translateY(5rem);
  }
}

@keyframes fadeInBottom {
  from {
    opacity: 0;
    transform: translateY(-5rem);
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(1px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-2px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(2px, 0, 0);
  }
}
