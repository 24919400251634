$pre: 'app';
$height-layout: calc(100vh - 64px);

.#{$pre}-loading {
  align-items: center;
  backdrop-filter: blur(3px);
  background-color: rgba($white, 0.5);
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  left: 0;
  padding: 1.5rem;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  width: 100vw;
  z-index: 2000;
}

.#{$pre}-container-login {
  align-items: center;
  background-attachment: fixed;
  background-image: linear-gradient($white, rgba($white, 0.8), $white), url('../assets/images/interface/bg_login.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.#{$pre}-bg-transparent {
  background-color: rgba($white, 0.85);
  backdrop-filter: blur(5px);
}

.#{$pre}-navbar-user {
  text-align: right;
  padding: 1rem 1.5rem;
  line-height: 1.2;
  max-width: 300px;
}

.#{$pre}-sidenav {
  height: $height-layout;
  left: 0;
  position: fixed;
  overflow: auto;
  z-index: 1001;
}

.#{$pre}-layout {
  &-content {
    margin-left: 0;

    @include media-breakpoint-up(md) {
      margin-left: 250px;
    }
  }

  &-backdrop {
    background-color: $gray-800;
    height: $height-layout;
    position: fixed;
    opacity: 0.8;
    width: 100%;
    z-index: 1000;
  }
}

.#{$pre}-sortable-list {
  border: 1px solid $border-color;
  background-color: $white;
  padding: 0 1rem;
  z-index: 1004;
}

.#{$pre}-subtitle {
  margin-top: 4rem;
  margin-bottom: 2rem;

  &::after {
    content: ' ';
    display: block;
    margin-top: 1rem;
    width: 5em;
    height: 0.5rem;
    border-radius: 0.5rem;
    @include gradient-x($orange, $yellow, 0%, 50%);
  }
}
