.ant-modal-confirm-body .ant-modal-confirm-title {
  color: $gray-800;
  font-weight: 600;
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item.bg-transparent:hover {
  background-color: theme-color() !important;

  > span {
    color: $white !important;
  }
}

.ant-spin-text {
  margin-top: 1rem;
}

.ant-pagination-item-container,
.ant-pagination-item-link,
.ant-pagination-jump-next,
.ant-pagination-jump-prev {
  display: flex !important;
  align-items: center !important;
}

.ant-picker,
.ant-select {
  width: 100%;
}

.ant-form-item {
  margin-bottom: 0.5rem;
}

.ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
  background-color: transparent !important;

  &:hover {
    background-color: rgba(theme-color(), 0.5);
  }
}

.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
.ant-picker-panel
  > :not(.ant-picker-date-panel)
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
.ant-picker-panel
  > :not(.ant-picker-date-panel)
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before,
.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
  .ant-picker-cell-inner::after,
.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
  .ant-picker-cell-inner::after {
  background: rgba(theme-color(), 0.8);
}

.ant-modal-title {
  font-size: 24px;
  font-family: 'Ecopetrol';
}

.ant-modal-close-x {
  width: 50px;
  height: 50px;
  line-height: 50px;
}

.ant-input-textarea-show-count {
  margin-bottom: 1rem;
}

.ant-tooltip {
  z-index: 2001;
}

.anticon {
  vertical-align: 0.075em;
}

.ant-tree-show-line .ant-tree-switcher-line-icon {
  vertical-align: 0;
}

.ant-tree-checkbox-inner::after {
  top: 45%;
}

.ant-layout-sider {
  background: $gray-900;

  .ant-menu.ant-menu-dark {
    background: $gray-900;
  }

  .ant-menu-vertical > .ant-menu-item,
  .ant-menu-vertical-left > .ant-menu-item,
  .ant-menu-vertical-right > .ant-menu-item,
  .ant-menu-inline > .ant-menu-item,
  .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-sub.ant-menu-inline > .ant-menu-item,
  .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 25px;
    line-height: 25px;

    > a:hover {
      text-decoration: none;
    }
  }

  .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open.ant-menu-submenu-selected {
    > .ant-menu-submenu-title:not(.bg-transparent) {
      background-color: rgba(theme-color(), 0.3);
      height: 40px;
      line-height: 40px;
      margin: 0;
    }
  }

  .ant-menu.ant-menu-sub.ant-menu-inline:not(.bg-transparent) {
    background-color: rgba($black, 0.25) !important;
    margin-bottom: 1rem;
  }

  .ant-menu-item.ant-menu-item-only-child.ant-menu-item-selected:not(.bg-transparent) {
    background-color: theme-color() !important;
    height: 40px;
    line-height: 40px;

    > a {
      color: $white !important;
      font-weight: 600;
    }
  }
}

.ant-menu-submenu-popup {
  display: none;
}

.ant-alert-message {
  color: $gray-900;
}

.ant-alert-with-description .ant-alert-message {
  color: $gray-900;
  font-weight: bold !important;
}

.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected,
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected,
.ant-dropdown-menu-item-selected > a,
.ant-dropdown-menu-submenu-title-selected > a {
  color: $gray-900;
  font-weight: bold;
  background-color: rgba(theme-color(), 0.1);
}
