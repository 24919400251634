@import './bootstrap/bootstrap-reboot.scss';
@import './bootstrap/bootstrap-grid.scss';

// Opcionales
@import './bootstrap/_utilities';
@import './bootstrap/_type';
@import './bootstrap/_card';

.container-fluid {
  padding: 0 1.5rem !important;
}
