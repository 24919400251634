@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600;700&family=Work+Sans:wght@400;500&display=swap');

html,
body {
  font-family: 'Roboto', sans-serif !important;
  font-size: 14px;
  font-weight: 400 !important;
  margin: 0 !important;
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: theme-color('primary');
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

label {
  color: $gray-900 !important;
}

button:focus {
  outline: 0;
  outline: none;
}
